import React, { useState, useEffect } from "react";

const VALIDECODE = "free4now";
const VALIDECODE2 = "symme3ko";


const URL = "https://www.paypal.com/donate/?business=C4SUCDJG6ZG2Q&no_recurring=0&item_name=The+best+tool+is+a+free+tool.%0AUse+the+code+free4now+on+sketch-app.com.%0AYou+can+always+help+me+keep+it+free%3A%29&currency_code=USD";

function PaymentModal({ showPayModal, setShowPayModal, setAvailableDownload }) {
    const [code, setCode] = useState("");
    const [error, setError] = useState(false);

    const handleSubmitCode = () => {

        if (code === VALIDECODE) {
            sessionStorage.setItem("isPaid", true);
            setAvailableDownload(true);
            setShowPayModal(false);
        } else {
            setError(true);
        }

        if (code === VALIDECODE2) {
            sessionStorage.setItem("isPaid", true);
            setAvailableDownload(true);
            setShowPayModal(false);
        } else {
            setError(true);
        }

    }

    const handleSubscribe = () => {
        window.open(URL, '_blank').focus();
    }

    return (
        <div style={{
            zIndex: 100,
            position: 'absolute',
            width: "100%",
            height: "100%",
            display: showPayModal ? "flex" : "none",
            backgroundColor: "#00000088",
            alignItems: 'start',
            justifyContent: 'center',
        }}
        >
            <div style={{
                width: '100%',
                height: '100%',
            }} onClick={() => {setShowPayModal(false)}}></div>
            <div style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#ffd479',
                border: "0px solid #005493",
                borderRadius: "5px",
                padding: "30px",
            }}>
                <p style={{
                    fontSize: '12pt',
                    fontWeight: '500',
                    fontFamily: 'system-ui',
                    color: '#000'
                }}>
                    Activation code required? Oh No!
                </p>
                <input style={{
                    width: "100%",
                    fontSize: "18pt",
                    borderRadius: "4px",
                    borderWidth: "0px",
                    padding: "9px",
                    textAlign: "center",
                    fontFamily: 'system-ui',
                }}
                    value={code}
                    onChange={(e) => {
                        setError(false);
                        setCode(e.target.value);
                    }}
                />
                <p style={{
                    fontSize: '16pt',
                    fontWeight: '400',
                    margin: 0,
                    fontFamily: 'system-ui',
                    color: '#f00',
                    display: error ? "block" : "none"
                }}>
                    Code does not match.
                </p>
                <div style={{
                    width: "100%",
                    margin: '20px 0px',
                    background: '#005493',
                    fontSize: "16pt",
                    borderRadius: "4px",
                    padding: "10px",
                    fontFamily: 'system-ui',
                    color: '#FFF',
                    cursor: 'pointer'
                }}
                onClick={handleSubmitCode}
                >Submit code</div>
                <p style={{
                    fontSize: '12pt',
                    fontWeight: '400',
                    margin: 0,
                    fontFamily: 'system-ui',
                    color: '#000'
                }}>That is ok, find your FREE activation code below:)
                </p>

                <div style={{ marginTop: "20px" }}></div>
                <div style={{
                    width: "100%",
                    margin: '20px 0px',
                    background: '#008F00',
                    fontSize: "16pt",
                    borderRadius: "4px",
                    padding: "10px",
                    fontFamily: 'system-ui',
                    color: '#FFF',
                    cursor: 'pointer'
                }}
                onClick={handleSubscribe}>Get FREE activation code</div>


            </div>
        </div>

    );
}

export default PaymentModal;
